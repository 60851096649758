.Login {
    width: 100%;
    padding-top:600px;
    max-width: 600px;
    margin: 0 auto; /* Centers the login box horizontally */
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.Login h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333; /* Darker color for better contrast */
}

.Login input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.Login button {
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.Login button:hover {
    background-color: #555; /* Changes color when hovered */
}

.logo {
    margin: 0 auto;
    width: 100%;
    border: 1px solid #555;
    border-radius: 5px;
    max-width: 640px;
    background-color:#555;
    text-align: center;
    margin-bottom: 20px;
}

.logo img {
    text-align: center;
    width: 150px;
    height: auto;
    display: inline-block;
    padding: 10px;
}
