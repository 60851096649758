/* Container for the entire table and filter elements */
.table-container {
    margin-top: 20px; /* Space above the table */
    overflow-x: auto; /* Allows horizontal scrolling for the table */
}

/* Main table styling */
.data-table {
    border-collapse: collapse; /* Enables individual cell border control */
    width: 100%; /* Full width for responsiveness */
    table-layout: fixed; /* Important for consistent column widths */
}

/* Styling for table headers and cells */
.data-table th,
.data-table td {
    border: 1px solid #e0e0e0; /* Light gray border for cells */
    padding: 12px 15px; /* Padding for cells */
    text-align: left; /* Left-align text */
    position: relative; /* Necessary for positioning the resizer */
    overflow: hidden; /* Prevents overflow of content */
    transition: background-color 0.2s; /* Smooth transition for background color */
}

/* Header specific styles */
.data-table th {
    background-color: #f7f9fc; /* Light background for header */
    font-weight: 600; /* Bold header text */
    color: #333; /* Dark text color */
}

/* Header container for aligning table header */
.header-container {
    display: flex; /* Flexbox for alignment */
    justify-content: space-between; /* Space between table headers */
    align-items: center; /* Align items to the center */
    margin-bottom: 10px; /* Space below header for select boxes */
}

/* Styling for the select boxes */
.select-container {
    display: flex; /* Flexbox for alignment of select boxes */
    flex-direction: column; /* Stack select boxes vertically */
    margin-top: 10px; /* Space above select boxes */
}

/* Styling for individual select boxes */
.filter-select {
    margin-bottom: 6px; /* Space below each select box */
    background: #ffffff; /* White background for select boxes */
    border: 1px solid #007BFF; /* Blue border */
    padding: 6px; /* Padding for select boxes */
    width: 100%; /* Full width for select boxes */
    box-sizing: border-box; /* Include padding and border in width */
    border-radius: 4px; /* Rounded corners */
    color: #007BFF; /* Blue text color */
    font-size: 14px; /* Font size */
    transition: border-color 0.2s ease; /* Smooth transition */
}

/* Focus state for select boxes */
.filter-select:focus {
    border-color: #0056b3; /* Darker blue on focus */
    outline: none; /* Remove default outline */
}

/* Button styling */
.toggle-button {
    margin: 12px 0; /* Margin around button */
    padding: 10px 18px; /* Padding for button */
    background-color: #007BFF; /* Blue background */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s; /* Smooth transition */
    text-align: center;
}

/* Button hover effect */
.toggle-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly scale up on hover */
}

/* Resizer handle for table columns */
.resizer {
    width: 5px; /* Width of the resizer handle */
    cursor: col-resize; /* Change cursor to indicate resizing */
    background-color: transparent; /* Transparent background */
    position: absolute; /* Position absolutely to overlay the header */
    top: 0; /* Align to the top of the header */
    right: 0; /* Align to the right of the header */
    height: 100%; /* Full height of the header */
}

/* Resizer hover effect */
.resizer:hover {
    background-color: rgba(0, 123, 255, 0.3); /* Change color on hover */
}

/* Row hover effect */
.data-table tbody tr:hover {
    background-color: #f1f7ff; /* Light blue background on row hover */
}

/* Selected row styling */
.data-table tbody tr.selected {
    background-color: lightgreen; /* Light green background for selected row */
}

/* Styling for empty state message */
.empty-state {
    text-align: center;
    font-size: 1.5em; /* Increase font size */
    color: #888; /* Grey color for empty state text */
    padding: 20px; /* Add some padding */
}
