/* Style for the main container that holds both the sidebar and content */
.main-wrapper {
  display: flex; /* Enables side-by-side layout */
  height: 100vh; /* Makes the sidebar and content take full viewport height */
}

/* Sidebar styling */
.slidebar {
  display: flex;
  flex-direction: column;
  width: 200px; /* Fixed width for the sidebar */
  min-width: 200px; /* Prevents sidebar from shrinking */
  flex-shrink: 0; /* Prevents sidebar from resizing when content expands */
  background-color: #f8f9fa;
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for a subtle separation */
}

/* Content container styling */
.container {
  flex: 1; /* Takes up remaining space */
  padding: 20px;
  overflow-y: auto; /* Allows scrolling if content overflows */
}
.slidebar .nav {
  margin: 5px 0; /* Space between buttons */
  border: none; /* Remove default border */
  background-color: transparent; /* Transparent background */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  text-align: left; /* Align text to the left */
  padding: 10px; /* Padding inside the button */
  width: 100%; /* Make buttons take full width */
}

.slidebar .nav:hover {
  background-color: #e0e0e0; /* Change background on hover */
}

.slidebar button.active {
  background-color: #007bff; /* Highlight active tab */
  color: white; /* Change text color for active tab */
}
body {
  font-family: Arial, sans-serif;
  background-color: #f0f4f8; /* Light background color */
  margin: 0;
  padding: 20px;
}

form {
  background-color: #ffffff; /* White background for forms */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px; /* Space between forms */
  padding: 20px; /* Inner padding */
}

.form-group {
  margin-bottom: 15px; /* Space between form elements */
}

label {
  font-weight: bold; /* Bold labels */
  display: block; /* Block display for labels */
  margin-bottom: 5px; /* Space below labels */
}

input[type="text"],
input[type="number"],
input[type="datetime-local"],
select {
  width: 100%; /* Full width for inputs */
  padding: 10px; /* Inner padding for inputs */
  border: 1px solid #ddd; /* Light border */
  border-radius: 4px; /* Slightly rounded corners */
  transition: border-color 0.3s; /* Smooth transition on focus */
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="datetime-local"]:focus,
select:focus {
  border-color: #4a90e2; /* Blue border on focus */
  outline: none; /* Remove default outline */
}

.btn {
  background-color: #4CAF50; /* Green background for buttons */
  color: white; /* White text for buttons */
  padding: 10px 15px; /* Inner padding */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
  display: inline-block; /* Inline block for buttons */
}

.btn:hover {
  background-color: #45a049; /* Darker green on hover */
}

.notification {
  margin-top: 10px; /* Space above notifications */
  font-weight: bold; /* Bold notifications */
  color: #e74c3c; /* Red color for error notifications */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db; /* Blue color for spinner */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Spinner animation */
  margin: 10px auto; /* Center spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Style for the refresh button */
.slidebar .Refresh {
  background-color: #007BFF; /* Blue color for the refresh button */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 15px; /* Inner padding */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  margin-bottom: 20px; /* Space below the button */
  transition: background-color 0.3s; /* Smooth transition */
}

.slidebar .Refresh:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
