/* Dashboard.css */

.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 200px; /* Fixed width for the sidebar */
  min-width: 200px; /* Prevents sidebar from shrinking */
  flex-shrink: 0; /* Prevents sidebar from resizing when content expands */
  background-color: #f8f9fa;
  padding: 10px;
}

.sidebar button {
  margin: 5px 0; /* Space between buttons */
  border: none; /* Remove default border */
  background-color: transparent; /* Transparent background */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  text-align: left; /* Align text to the left */
  padding: 10px; /* Padding inside the button */
  width: 100%; /* Make buttons take full width */
}

.sidebar button:hover {
  background-color: #e0e0e0; /* Change background on hover */
}

.sidebar button.active {
  background-color: #007bff; /* Highlight active tab */
  color: white; /* Change text color for active tab */
}

.tab-content {
  padding: 20px; /* Padding around the content area */
  border-left: 1px solid #ddd; /* Light border between sidebar and content */
  background-color: #ffffff; /* White background for content */
  overflow-x: auto;
  overflow-y: auto;
}
